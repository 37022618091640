
import React, { Component } from 'react';

export default class InstagramIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="57px" height="57px" viewBox="0 0 57 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>InstagramIcon</title>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-903.000000, -730.000000)" fill="#FFFFFF">
      <g id="Icons" transform="translate(571.000000, 728.000000)">
        <path d="M338.869231,53.9333333 C337.874215,53.9333333 337.066667,53.1176 337.066667,52.1125 L337.066667,24.8 L344.276923,24.8 C343.337787,26.165625 343.020536,28.7129708 343.020536,30.3298708 C343.020536,40.0804333 350.892333,48.0138042 360.566695,48.0138042 C370.242859,48.0138042 378.114656,40.0804333 378.114656,30.3298708 C378.114656,28.7129708 377.887533,26.2038625 376.723077,24.8 L383.933333,24.8 L383.933333,52.1125 C383.933333,53.1176 383.125785,53.9333333 382.130769,53.9333333 L338.869231,53.9333333 Z M371.266667,30.4991232 C371.266667,36.4458235 366.446293,41.2666667 360.499125,41.2666667 C354.553707,41.2666667 349.733333,36.4458235 349.733333,30.4991232 C349.733333,24.5541765 354.553707,19.7333333 360.499125,19.7333333 C366.446293,19.7333333 371.266667,24.5541765 371.266667,30.4991232 Z M379.626667,9.6 C380.605547,9.6 381.4,10.3944533 381.4,11.3733333 L381.4,16.6933333 C381.4,17.6722133 380.605547,18.4666667 379.626667,18.4666667 L374.306667,18.4666667 C373.327787,18.4666667 372.533333,17.6722133 372.533333,16.6933333 L372.533333,11.3733333 C372.533333,10.3944533 373.327787,9.6 374.306667,9.6 L379.626667,9.6 Z M338.775875,2 C335.033469,2 332,5.02634375 332,8.75984375 L332,52.2401563 C332,55.9736563 335.033469,59 338.775875,59 L382.224125,59 C385.966531,59 389,55.9736563 389,52.2401563 L389,8.75984375 C389,5.02634375 385.966531,2 382.224125,2 L338.775875,2 Z" id="InstagramIcon" />
      </g>
    </g>
  </g>
</svg>
    );
  }
}