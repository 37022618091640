import React from "react";

// Styling.
import "./Styles/Footer.css"
// Assets.
import FooterWaveGraphic from "./Assets/FooterWaveGraphic";
import FooterIYELogo from "./Assets/FooterIYELogo";

// Styling.
import "./Styles/Footer.css"

const Footer = () => <footer className="footer-container">
    <p>copyright @ Life's a Mitch Podcast {new Date().getFullYear().toString()} | Site by <a href="http://inyourelement.fm" target="_blank">InYourElement</a></p>
</footer>

export default Footer;