
import React, { Component } from 'react';

export default class GooglePodcastIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="60px" height="61px" viewBox="0 0 60 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>GooglePodcastIcon</title>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-903.000000, -730.000000)" fill="#FFFFFF" fillRule="nonzero">
      <g id="Listen-On-Section" transform="translate(685.000000, 666.000000)">
        <g id="Icons" transform="translate(0.000000, 64.000000)">
          <path d="M277.134459,24.5516304 C277.512608,26.5720228 277.719093,28.687528 277.719093,30.8969948 C277.719093,48.1590393 266.162524,60.4347826 248.711693,60.4347826 C232.016872,60.4347826 218.494301,46.9122122 218.494301,30.2173913 C218.494301,13.5225704 232.016872,0 248.711693,0 C256.870964,0 263.687576,3.00260145 268.919933,7.87522774 L260.400931,16.3932226 L260.400931,16.3737971 C257.22868,13.3517702 253.206314,11.8034886 248.711693,11.8034886 C238.740385,11.8034886 230.638239,20.2265145 230.638239,30.1988292 C230.638239,40.1691294 238.740241,48.6124441 248.711693,48.6124441 C257.758204,48.6124441 263.91435,43.4373561 265.18161,36.3367008 L248.711693,36.3367008 L248.711693,24.5516304 L277.134459,24.5516304 Z" id="GooglePodcastIcon" />
        </g>
      </g>
    </g>
  </g>
</svg>
    );
  }
}