
import React, { Component } from 'react';

export default class FacebookIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" width={57} height={57} viewBox="0 0 57 57">
  <path fill="#FFF" fillRule="evenodd" d="M230.376187,3 C229.063406,3 228,4.06340625 228,5.37440625 L228,57.6255938 C228,58.9365938 229.063406,60 230.376187,60 L258.28125,60 L258.28125,38.625 L251.15625,38.625 L251.15625,29.71875 L258.28125,29.71875 L258.28125,22.59375 C258.28125,15.2318437 262.942781,11.6087812 269.51025,11.6087812 C272.655937,11.6087812 275.359875,11.8439063 276.147187,11.9472188 L276.147187,19.6422187 L271.592531,19.644 C268.021125,19.644 267.1875,21.3415313 267.1875,23.8317188 L267.1875,29.71875 L276.09375,29.71875 L274.3125,38.625 L267.1875,38.625 L267.33,60 L282.625594,60 C283.936594,60 285,58.9365938 285,57.6255938 L285,5.37440625 C285,4.06340625 283.936594,3 282.625594,3 L230.376187,3 Z" transform="translate(-228 -3)" />
</svg>
    );
  }
}