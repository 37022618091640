
import React, { Component } from 'react';

export default class ApplePodcastIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="50px" height="60px" viewBox="0 0 50 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>ApplePodcastIcon</title>
  <defs>
    <filter id="filter-1">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
    </filter>
  </defs>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-800.000000, -730.000000)">
      <g id="Listen-On-Section" transform="translate(685.000000, 666.000000)">
        <g id="ApplePodcastIcon" transform="translate(0.000000, 64.000000)" filter="url(#filter-1)">
          <g transform="translate(115.027536, 0.000000)">
            <path d="M40.826113,32.0509826 C40.7511739,24.5183913 46.9687043,20.9056 47.2467043,20.723087 C43.7523652,15.6139304 38.3084,14.912887 36.3708609,14.833113 C31.7415565,14.3629304 27.3334435,17.5599304 24.9849478,17.5599304 C22.641287,17.5599304 19.0127826,14.9020087 15.1739652,14.9696957 C10.1240348,15.0446348 5.46934783,17.9068261 2.87065217,22.4273478 C-2.37387826,31.5252 1.529,45.0082 6.63936522,52.3921217 C9.13773913,56.001287 12.1171739,60.0625043 16.0260957,59.9138348 C19.7936,59.7651652 21.2150261,57.4771043 25.7693913,57.4771043 C30.3237565,57.4771043 31.6025565,59.9138348 35.5876261,59.837687 C39.6391739,59.7639565 42.2076522,56.1596261 44.6854783,52.5359565 C47.553713,48.3429913 48.7346087,44.2829826 48.804713,44.0775043 C48.7164783,44.0364087 40.9070957,41.044887 40.826113,32.0509826 Z" id="Path" fill="#000000" fillRule="nonzero" />
            <path d="M33.3382435,9.94635652 C35.4111565,7.42985217 36.8156609,3.93309565 36.433713,0.452052174 C33.4421913,0.572921739 29.8209391,2.44035652 27.673087,4.95444348 C25.7488435,7.18569565 24.0663391,10.7380522 24.5171826,14.1562435 C27.8543913,14.4149043 31.2580783,12.4580261 33.3382435,9.94635652 Z" id="Path" fill="#000000" fillRule="nonzero" />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
    );
  }
}