import React from 'react';

export const HorizontalSpacer = ({style}) => <div style={{
    width: '-webkit-fill-available',
    ...style
}}></div>

export const VerticalSpacer = ({style}) => <div style={{
    height: '-webkit-fill-available',
    ...style
}}></div>