
import React, { Component } from 'react';

export default class PoddtoppenIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" width={70} height={70} viewBox="0 0 70 70">
  <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="round" strokeWidth="11.682" transform="translate(6 6)">
    <line x1="14.603" x2="14.603" y1="21.506" y2="46.197" />
    <line x1="43.277" x2="43.277" y1="10.089" y2="34.781" />
    <line x1=".266" x2=".266" y1="28.143" y2="28.674" />
    <line x1="57.614" x2="57.614" y1="28.143" y2="28.674" />
    <line x1="28.94" x2="28.94" y2="57.348" />
  </g>
</svg>
    );
  }
}