import React, { Component } from 'react';
import { Parallax, withController } from 'react-scroll-parallax';

// Styling
import './Styles/PhotoWithFilter.css';
import './Styles/Parallax.css';

const GradientFilter = (props) => <div
style={{
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 0,
  backgroundImage: !props.radial ?
    `linear-gradient(${props.firstColour},${props.secondColour})` :
    `radial-gradient(circle, ${props.firstColour} 00%, ${props.secondColour} 200%)`,
  // mixBlendMode: props.blendMode
}}
className={"gradient-filter " + (props.blendMode ? `mix-blend-mode-${props.blendMode}` : "")}></div>

const FlatFilter = ({
  colour, blendMode
}) =>
<div
style={{
  backgroundColor: colour,
  // mixBlendMode: blendMode,
  width: '100%',
  height: '100%',
  position: 'absolute'
}}
className={"flat-filter " + (blendMode ? `mix-blend-mode-${blendMode}` : "")}></div>

class PhotoWithFilterNoParallaxController extends Component {

  componentWillUpdate(){
    console.log("Updating cached parallax values.");
    this.props.parallaxController.update();
  }

  render(){
    return (this.props.parallax ?
    <Parallax styleOuter={{
      overflowY: "hidden",
    }} y={[-40, 40]} >
      <PhotoWithFilterInner {...this.props} />
    </Parallax> :
    <PhotoWithFilterInner {...this.props} />);
  }
}

const PhotoWithFilter = withController(PhotoWithFilterNoParallaxController);

class PhotoWithFilterInner extends Component {
  render(){
    return (
      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        margin: '0',
        zIndex: -1,
        ...this.props.style
      }} className="photo-filter">

        <GradientFilter {...this.props} />

        {
          this.props.flatFilter ?
          <FlatFilter {...this.props.flatFilter} /> : ""
        }

        <div
        className="filter-background"
        style={{
          // display: 'flex',
          backgroundImage: `url('${this.props.image}')`,
          backgroundSize: this.props.backgroundSize ? this.props.backgroundSize : 'cover',
          backgroundPosition: this.props.backgroundPosition ? this.props.backgroundPosition : 'center center',
          width: '100%',
          height: '100%'
        }}>
        </div>

      </div>
    );
  }
}

export {
  GradientFilter,
  FlatFilter,
  PhotoWithFilter
};
