
import React, { Component } from 'react';

export default class MailIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="60px" height="45px" viewBox="0 0 60 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>MailIcon</title>
  <defs>
    <filter id="filter-1">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
    </filter>
  </defs>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-949.000000, -738.000000)">
      <g id="Listen-On-Section" transform="translate(617.000000, 666.000000)">
        <g id="MailIcon" transform="translate(0.000000, 64.000000)" filter="url(#filter-1)">
          <g transform="translate(332.000000, 8.000000)">
            <path d="M30,30 C29.0025,30 28.125,29.6475 26.25,28.74 L0,15 L0,41.25 C0,43.3125 1.6875,45 3.75,45 L56.25,45 C58.3125,45 60,43.3125 60,41.25 L60,15 L33.75,28.74 C31.875,29.6475 30.9975,30 30,30 Z M56.25,0 L3.75,0 C1.6875,0 0,1.6875 0,3.75 L0,6.5925 L30,22.36125 L60,6.5925 L60,3.75 C60,1.6875 58.3125,0 56.25,0 Z" id="Shape" fill="#000000" fillRule="nonzero" />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
    );
  }
}