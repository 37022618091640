import React from 'react';
import { VerticalSpacer } from "./Util/Spacer";

// Styling.
import './Styles/Hero.css';

export const HeroText = ({
    header,
    subheader,
    light = true
}) => <div className="hero-text-container">
    { header ? <h1 className={light ? "light" : "dark"}>{header}</h1> : "" }
    { subheader ? <h2 className={light ? "light" : "dark"}>{subheader}</h2> : "" }
</div>

export const Hero = ({
    header,
    subheader,
    image,
    children
}) => <div className="hero-container">
    <HeroText header={header} subheader={subheader} />
    {children}
</div>