
import React, { Component } from 'react';

export default class TopPodcastIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" width={64} height={64} viewBox="0 0 64 64">
  <g fill="#FFF">
    <path d="M33.0093447,56.4773561 C36.5678177,56.4773561 39.5621425,55.7396239 42.0791111,54.1773675 C43.2508034,53.4830313 43.0815592,52.2640375 42.5438827,51.4438532 C41.9884136,50.597631 41.212059,50.2504629 39.3460305,51.1574392 C37.631888,51.9902088 35.6096337,52.6363754 32.9364391,52.6363754 C26.0195489,52.6363754 21.3414587,48.1540017 21.3414587,40.3513987 C21.3414587,32.6703047 25.8242668,28.1054781 32.5072526,28.1054781 C39.5591044,28.1054781 43.8288379,31.9065346 43.8288379,38.1902772 C43.8288379,41.7786935 42.9266352,44.5820761 40.177932,44.6879625 L40.177932,34.291146 C40.177932,33.1832458 39.5547652,32.5887206 38.3978274,32.5887206 C37.2986063,32.5887206 36.5378743,33.1424535 36.5378743,34.2907122 L36.5378743,35.0909348 C35.7593498,33.3898113 34.1398103,32.2189871 31.9322554,32.2189871 C27.7059179,32.2189871 24.8751962,35.4602353 24.8751962,40.3019286 C24.8751962,45.1844136 27.7059179,48.4256618 31.9322554,48.4256618 C34.340734,48.4256618 35.8895378,47.4930813 36.5378743,45.9230139 L36.5378743,46.2302577 C36.5378743,47.5147799 37.5155864,47.9322493 39.1529177,47.9322493 L39.1937101,47.9322493 C45.1945105,47.9322493 48.0694962,43.8777603 48.0694962,37.9889217 C48.0694962,28.0946311 40.3016102,24.2323861 32.5771202,24.2323861 C24.7224421,24.2323861 17.3017242,29.1361354 17.3017242,40.3323063 C17.3017242,51.7454573 25.0696102,56.4756226 33.0110803,56.4756226 L33.0093447,56.4773561 Z M32.5467438,44.684492 C30.5427156,44.6840583 28.6437064,43.2901786 28.6437064,40.1999484 C28.6437064,37.1092844 30.5761307,35.7162721 32.5467438,35.7162721 C34.9231091,35.7162721 36.4875354,37.4968104 36.4875354,40.2003821 C36.4875354,43.2602346 35.239032,44.684492 32.5467438,44.684492 Z" />
    <path d="M58.7388137,29.4816688 L59.0718002,29.4816688 L59.0718002,27.0025284 C59.0718002,12.1276865 46.9510926,0 32.0332986,0 C17.1155047,0 4.92819979,12.1276865 4.92819979,27.0025284 L4.92819979,29.4816688 L5.26118626,29.4816688 C2.06451613,31.8268015 0,35.7130215 0,40.068268 C0,47.17067 5.39438085,52.9329962 12.0541103,52.9329962 C12.8532778,52.9329962 13.5858481,52.2629583 13.5858481,51.391909 L13.5858481,28.7446271 C13.5858481,27.9405815 12.9198751,27.2035398 12.0541103,27.2035398 C11.1217482,27.2035398 10.2559834,27.3375474 9.39021852,27.5385588 L9.39021852,27.0025284 C9.39021852,14.6068268 19.5130073,4.5562579 31.9667014,4.5562579 C44.4203954,4.5562579 54.5431842,14.6068268 54.5431842,27.0025284 L54.5431842,27.5385588 C53.7440166,27.3375474 52.8116545,27.2035398 51.9458897,27.2035398 C51.1467222,27.2035398 50.4141519,27.8735777 50.4141519,28.7446271 L50.4141519,51.4589128 C50.4141519,52.2629583 51.0801249,53 51.9458897,53 C58.5390219,53 64,47.2376738 64,40.1352718 C63.9334027,35.7130215 61.8022893,31.8268015 58.7388137,29.4816688 Z" />
  </g>
</svg>
    );
  }
}