import React from "react";
import { NavigationBar } from "./Navigation";
// import { Route, BrowserRouter as Router } from "react-router-dom";

// Sections.
import HomeSection from "./HomeSection"
import AboutSection from "./AboutSection";
import { PodcastSection } from "./PodcastSection";
import Footer from "./Footer";


// Styling.
import "./Styles/App.css";

// Model.
const links = [
  { title: "podcast", url: "#" },
  { title: "about", url: "#" },
  { title: "contact", url: "#" }
]

// Views.
function App() {
  return (
    <div className="App">
      {/* <NavigationBar links={links} /> */}
      <HomeSection />
      {/* <AboutSection />
      <PodcastSection /> */}
    </div>
  );
}

export default App;
