
import React, { Component } from 'react';

export default class FooterWaveGraphic extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={1600} viewBox="0 0 1600 137">
  <defs>
    <linearGradient id="footerwavegraphic-a" x1="50%" x2="50%" y1="0%" y2="100%">
      <stop offset="0%" stopColor="#EFEFF1" />
      <stop offset="100%" stopColor="#DBDBDE" />
    </linearGradient>
    <path id="footerwavegraphic-b" d="M0,17 C78,43 208.333333,56 391,56 C521.151951,56 741.422981,0 1142,0 C1253.33333,0 1406,0 1600,0 L1600,137 L0,137 L0,17 Z" />
    <path id="footerwavegraphic-c" d="M0,30 C78,10 208.333333,0 391,0 C521.151951,0 719.422981,30 1120,30 C1231.33333,30 1391.33333,24.3333333 1600,13 L1600,150 L0,150 L0,30 Z" />
  </defs>
  <g fill="none" fillRule="evenodd">
    <use fill="url(#footerwavegraphic-a)" xlinkHref="#footerwavegraphic-b" />
    <use fill="#E4E4E6" xlinkHref="#footerwavegraphic-c" />
  </g>
</svg>
    );
  }
}