
import React, { Component } from 'react';

export default class WaveOverlay extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} viewBox="0 0 1600 111" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>WaveOverlay</title>
  <g id="Site-Designs" stroke="white" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Landing-White-on-Colour" transform="translate(0.000000, -965.000000)" fill="#FFFFFF">
      <path d="M2.27373675e-13,996 C98.6666667,975.333333 205.666667,965 321,965 C614,965 790.170231,1032 1249,1032 C1327,1032 1444,1020 1600,996 L1600,1076 L2.27373675e-13,1076 L2.27373675e-13,996 Z" id="WaveOverlay" />
    </g>
  </g>
</svg>
    );
  }
}