
import React, { Component } from 'react';

export default class BookYourselfCalloutBackgroundGraphic extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="508px" height="309px" viewBox="0 0 508 309" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>BookYourselfCalloutBackgroundGraphic</title>
  <defs>
    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
      <stop stopColor="#F4B400" offset="0%" />
      <stop stopColor="#F76B1C" offset="100%" />
    </linearGradient>
    <path d="M349.272466,142.55194 L487.411087,227.478639 C494.468317,231.817377 496.672091,241.055635 492.333353,248.112866 C491.98722,248.675873 491.604256,249.21539 491.186965,249.727888 L252.010751,543.473327 C246.780061,549.897424 237.331988,550.864872 230.907891,545.634182 C230.724977,545.485248 230.545601,545.33202 230.369914,545.174627 L110.651597,437.922057 C104.688451,432.579828 103.971884,423.496131 109.023641,417.284991 L329.779551,145.865423 C334.526759,140.028727 342.863327,138.611644 349.272466,142.55194 Z" id="path-2" />
    <filter x="-7.5%" y="-9.8%" width="123.6%" height="123.0%" filterUnits="objectBoundingBox" id="filter-3">
      <feOffset dx={18} dy={8} in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur stdDeviation={15} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0610249126 0" type="matrix" in="shadowBlurOuter1" />
    </filter>
    <path d="M410.65116,93.493674 L506.77883,225.407571 C510.742378,230.846661 510.5981,238.259846 506.425918,243.540595 L287.587412,520.525505 C283.081277,526.228941 275.134339,527.880847 268.728581,524.445632 L116.729634,442.933195 C109.428904,439.018034 106.684357,429.925758 110.599518,422.625027 C111.08647,421.716991 111.665687,420.861545 112.327993,420.072239 L387.037776,92.6858692 C392.362803,86.3397492 401.824136,85.5119898 408.170256,90.8370167 C409.101861,91.6187254 409.934944,92.5108264 410.65116,93.493674 Z" id="path-4" />
    <filter x="-6.7%" y="-8.1%" width="123.2%" height="120.9%" filterUnits="objectBoundingBox" id="filter-5">
      <feOffset dx={18} dy={8} in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur stdDeviation={15} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0610249126 0" type="matrix" in="shadowBlurOuter1" />
    </filter>
  </defs>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-564.000000, -1480.000000)">
      <g id="Book-Yourself-Section" transform="translate(520.906215, 1316.213958)">
        <g id="BookYourselfCalloutBackgroundGraphic" transform="translate(0.462203, 0.215304)">
          <g id="Triangle-Copy-3" opacity="0.404157366" transform="translate(301.000000, 345.667263) rotate(62.000000) translate(-301.000000, -345.667263) ">
            <use fill="black" fillOpacity={1} filter="url(#filter-3)" xlinkHref="#path-2" />
            <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2" />
          </g>
          <g id="Triangle-Copy-2" transform="translate(306.973885, 304.191326) rotate(50.000000) translate(-306.973885, -304.191326) ">
            <use fill="black" fillOpacity={1} filter="url(#filter-5)" xlinkHref="#path-4" />
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-4" />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
    );
  }
}