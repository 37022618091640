import React from 'react';
import { Hero } from './Hero';
import { PhotoWithFilter } from "./Filter";
import WaveOverlay from "./Assets/WaveOverlay";
import Footer from './Footer';
import { VerticalSpacer } from './Util/Spacer';

// Images.
import BackgroundImageSrc from './Assets/Images/LifesAMitchBackground.jpg';

// Assets.
import ApplePodCastIcon from "./Assets/ApplePodcastIcon";
import SoundCloudIcon from "./Assets/SoundcloudIcon";
import SpotifyPodcastIcon from "./Assets/SpotifyPodcastIcon";
import IHeartRadioIcon from "./Assets/iHeartRadioIcon";
import FacebookIcon from "./Assets/FacebookIcon";
import InstagramIcon from "./Assets/InstagramIcon";
import GooglePodcastIcon from "./Assets/GooglePodcastIcon";
import TopPodcastIcon from "./Assets/TopPodcastIcon";
import PoddtoppenIcon from "./Assets/PoddtoppenIcon"
import MailIcon from "./Assets/MailIcon"

// Styling.
import './Styles/HomeSection.css'

// Model.
const links = [
    { title: "Apple Podcasts", icon: <ApplePodCastIcon />, url: "https://podcasts.apple.com/au/podcast/lifes-a-mitch-podcast/id1558841578" },
    { title: "Spotify Podcassts", icon: <SpotifyPodcastIcon />, url: "https://open.spotify.com/show/3bkXh5PV4KzcF4ZLzDazP9?si=Dr4OelFpQDigRRVNKUUIZQ" },
    { title: "Soundcloud", icon: <SoundCloudIcon />, url: "https://soundcloud.com/mitch-kelly-7" },
    { title: "Google Podcasts", icon: <GooglePodcastIcon />, url: "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zb3VuZGNsb3VkLmNvbS91c2Vycy9zb3VuZGNsb3VkOnVzZXJzOjEwMjg3Mjc5Ni9zb3VuZHMucnNz" },
    { title: "Top Podcast", icon: <TopPodcastIcon />, url: "https://toppodcast.com/podcast_feeds/lifes-a-mitch-podcast/" },
    { title: "Poddtoppen", icon: <PoddtoppenIcon />, url: "https://poddtoppen.se/podcast/1558841578/lifes-a-mitch-podcast" },
    { title: "iHeart Radio", icon: <IHeartRadioIcon />, url: "https://www.iheart.com/podcast/269-lifes-a-mitch-podcast-83149639/" },
    { title: "Instagram", icon: <InstagramIcon />, url: "https://www.instagram.com/lifesamitch_podcast/" },
    { title: "Facebook", icon: <FacebookIcon />, url: "https://www.facebook.com/lifesamitchpodcast" },
    { title: "Email", icon: <MailIcon />, url: "mailto:Lifesamitchpodcast@gmail.com" }
]

const heroContent = {
    header: <>Life's a <mark>Mitch</mark> Podcast</>,
    subheader: "Podcast sharing good stories and banter",
    image: ""
}

// Views.
const LinkSection = () =>
    <div className="listen-on-container">
       {
        links.map(({title, url, icon}, key) => 
            <a key={key} target="blank" title={title} href={url}>{icon}</a>
        )
       }
    </div>

export default function HomeSection() {
    return (
        <div className="homesection-container">
            <div style={{ marginBottom: 'auto' }} />
            <Hero {...heroContent}>
                <LinkSection />
                <p className="homesection-download-link-container">Download episodes on <a href="https://podtail.com/podcast/life-s-a-mitch-podcast/" target="_blank" >podtail</a></p>
            </Hero>
            <PhotoWithFilter 
                image={BackgroundImageSrc}
                firstColour="rgba(0,0,0,0.5)"
                secondColour="rgba(0,0,0,6)"
            />
            <div>
            </div>
            <Footer />
        </div>
    )
}