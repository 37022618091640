
import React, { Component } from 'react';

export default class SoundcloudIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" width={102} height={57} viewBox="0 0 102 57">
  <defs>
    <filter id="soundcloudicon-a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
    </filter>
  </defs>
  <g fill="none" fillRule="evenodd" filter="url(#soundcloudicon-a)" transform="translate(-452)">
    <path fill="#000" fillRule="nonzero" d="M85.4866667 25.3333333C84.3593333 25.3333333 83.251 25.4536667 82.187 25.6816667 80.762 11.286 68.583 0 53.82 0 52.072 0 50.6533333 1.41866667 50.6533333 3.16666667L50.6533333 53.8333333C50.6533333 55.5813333 52.072 57 53.82 57L85.4866667 57C94.2203333 57 101.32 49.9003333 101.32 41.1666667 101.32 32.4393333 94.2203333 25.3333333 85.4866667 25.3333333zM41.1566667 6.33333333C39.4086667 6.33333333 37.99 7.752 37.99 9.5L37.99 53.8333333C37.99 55.5813333 39.4086667 57 41.1566667 57 42.9046667 57 44.3233333 55.5813333 44.3233333 53.8333333L44.3233333 9.5C44.3233333 7.752 42.9046667 6.33333333 41.1566667 6.33333333zM28.4933333 19C26.7453333 19 25.3266667 20.4186667 25.3266667 22.1666667L25.3266667 53.8333333C25.3266667 55.5813333 26.7453333 57 28.4933333 57 30.2413333 57 31.66 55.5813333 31.66 53.8333333L31.66 22.1666667C31.66 20.4186667 30.2413333 19 28.4933333 19zM15.83 19C14.082 19 12.6633333 20.4186667 12.6633333 22.1666667L12.6633333 53.8333333C12.6633333 55.5813333 14.082 57 15.83 57 17.578 57 18.9966667 55.5813333 18.9966667 53.8333333L18.9966667 22.1666667C18.9966667 20.4186667 17.578 19 15.83 19zM3.16666667 28.5C1.41866667 28.5 7.59392549e-14 29.9186667 7.59392549e-14 31.6666667L7.59392549e-14 50.6666667C7.59392549e-14 52.4146667 1.41866667 53.8333333 3.16666667 53.8333333 4.91466667 53.8333333 6.33333333 52.4146667 6.33333333 50.6666667L6.33333333 31.6666667C6.33333333 29.9186667 4.91466667 28.5 3.16666667 28.5z" transform="translate(452)" />
  </g>
</svg>
    );
  }
}