
import React, { Component } from 'react';

export default class SelectedEpisodeBackgroundGraphic extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={1487} height={696} viewBox="0 0 1487 696">
  <defs>
    <linearGradient id="selectedepisodebackgroundgraphic-c" x1="13.123%" x2="87.475%" y1="31.881%" y2="61.556%">
      <stop offset="0%" stopColor="#2E23AE" />
      <stop offset="100%" stopColor="#6DA8D7" />
    </linearGradient>
    <path id="selectedepisodebackgroundgraphic-b" d="M92.5117056,46.8865392 L1431.80984,126.425755 C1439.87005,126.90444 1446.10509,133.677546 1445.91648,141.749751 L1435.35982,593.565464 C1435.17181,601.612236 1428.66633,608.076303 1420.61852,608.212924 L57.2379656,631.357856 C48.9548878,631.498471 42.1261359,624.8977 41.9855212,616.614623 C41.978785,616.217815 41.9877992,615.820897 42.0125427,615.424805 L76.651624,60.9249435 C77.1670073,52.674726 84.2599453,46.3964776 92.5117056,46.8865392 Z" />
    <filter id="selectedepisodebackgroundgraphic-a" width="107%" height="116.9%" x="-3.5%" y="-6.7%" filterUnits="objectBoundingBox">
      <feOffset dy={10} in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={15} />
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0506446678 0" />
    </filter>
    <path id="selectedepisodebackgroundgraphic-e" d="M66.0185411,56.5004976 L1403.30838,104.435539 C1411.14923,104.716594 1417.44882,110.992579 1417.7593,118.832318 L1436.60109,594.587467 C1436.92892,602.865249 1430.48421,609.841478 1422.20643,610.169311 C1422.08848,610.173982 1421.97048,610.177261 1421.85246,610.179147 L75.1862075,631.694512 C66.9110983,631.826721 60.0925085,625.231722 59.9488468,616.956803 L50.4834725,71.7512479 C50.33967,63.4682249 56.937812,56.636933 65.2208351,56.4931305 C65.4867554,56.4885139 65.7527514,56.4909704 66.0185411,56.5004976 Z" />
    <filter id="selectedepisodebackgroundgraphic-d" width="107.1%" height="117.2%" x="-3.6%" y="-6.8%" filterUnits="objectBoundingBox">
      <feOffset dy={10} in="SourceAlpha" result="shadowOffsetOuter1" />
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={15} />
      <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0506446678 0" />
    </filter>
  </defs>
  <g fill="none" fillRule="evenodd" transform="translate(-.209 -.934)">
    <g opacity=".7" transform="rotate(2 743.627 338.817)">
      <use fill="#000" filter="url(#selectedepisodebackgroundgraphic-a)" xlinkHref="#selectedepisodebackgroundgraphic-b" />
      <use fill="url(#selectedepisodebackgroundgraphic-c)" xlinkHref="#selectedepisodebackgroundgraphic-b" />
    </g>
    <use fill="#000" filter="url(#selectedepisodebackgroundgraphic-d)" xlinkHref="#selectedepisodebackgroundgraphic-e" />
    <use fill="#FFF" xlinkHref="#selectedepisodebackgroundgraphic-e" />
  </g>
</svg>
    );
  }
}