
import React, { Component } from 'react';

export default class iHeartRadioIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} xmlns="http://www.w3.org/2000/svg" width={73} height={55} viewBox="0 0 73 55">
  <defs>
    <filter id="iheartradioicon-a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
    </filter>
  </defs>
  <g fill="none" fillRule="evenodd" filter="url(#iheartradioicon-a)" transform="translate(-593 -3)">
    <g transform="translate(593 3)">
      <path fill="#C6002B" fillRule="nonzero" d="M16.7181039,36.0510144 C10.6913767,30.6753157 7.65002521,25.0740632 7.65002521,19.7547529 C7.65002521,14.4354427 10.6167423,8.87178247 14.5350479,6.39069076 C16.4568835,5.56366019 17.2592032,7.93197501 16.0277358,8.75900558 C12.9117499,10.7326013 10.4488149,15.356454 10.4488149,19.7923452 C10.4488149,24.2282365 13.1916288,29.1152353 18.5839637,33.9270495 C19.8900656,35.5623145 17.874937,37.0284141 16.7367625,36.0322182 L16.7181039,36.0510144 Z M25.767524,31.2579963 C21.681291,28.9460699 18.9384771,24.4349941 18.9384771,20.0930836 C18.9384771,15.7511731 21.2708018,11.8791664 24.9652042,9.94316298 C26.924357,9.24770546 27.577408,11.6912049 26.2713061,12.4242547 C23.4725164,13.8903543 21.7372668,16.8037575 21.7372668,19.9803067 C21.7559254,23.4387982 23.9203227,26.9724742 27.1482602,28.7957007 C28.7715582,30.1302273 27.0363086,31.9346577 25.767524,31.2767924 L25.767524,31.2579963 Z M36.9813414,27.1416396 C33.6414523,27.1416396 30.917297,24.4161979 30.917297,21.0516872 C30.917297,17.6871765 33.6414523,14.9429387 36.9813414,14.9429387 L36.9813414,9.17252086 C33.3615734,3.74043371 27.1296016,0 20.1326273,0 C9.01210287,0 0,9.07854011 0,20.2810451 C0,27.3859895 4.47806354,32.5173383 9.14271306,37.2539679 L27.4094806,54.6592022 C28.5849723,55.392252 29.9283913,54.9035521 29.9470499,53.5690255 L29.9470499,40.0170019 C29.9470499,36.0886067 33.100353,32.9120574 37,32.9120574 L36.9813414,27.1416396 Z" />
      <path fill="#C6002B" fillRule="nonzero" d="M16.724,35.9744898 C10.7485,30.6241497 7.733,25.0493197 7.733,19.755102 C7.733,14.4608844 10.6745,8.92346939 14.5595,6.45408163 C16.465,5.63095238 17.2605,7.98809524 16.0395,8.81122449 C12.95,10.7755102 10.508,15.377551 10.508,19.792517 C10.508,24.207483 13.2275,29.0714286 18.574,33.8605442 C19.869,35.4880952 17.871,36.9472789 16.7425,35.9557823 L16.724,35.9744898 Z M25.6965,31.2040816 C21.645,28.9030612 18.9255,24.4132653 18.9255,20.0918367 C18.9255,15.7704082 21.238,11.9166667 24.901,9.98979592 C26.8435,9.29761905 27.491,11.7295918 26.196,12.4591837 C23.421,13.9183673 21.7005,16.8180272 21.7005,19.9795918 C21.719,23.4217687 23.865,26.9387755 27.0655,28.7534014 C28.675,30.0816327 26.9545,31.877551 25.6965,31.2227891 L25.6965,31.2040816 Z M36.815,27.1071429 C33.5035,27.1071429 30.8025,24.3945578 30.8025,21.0459184 C30.8025,17.6972789 33.5035,14.9659864 36.815,14.9659864 L36.815,9.22278912 C33.226,3.81632653 27.047,0.093537415 20.1095,0.093537415 C9.0835,0.093537415 0.148,9.1292517 0.148,20.2789116 C0.148,27.3503401 4.588,32.457483 9.213,37.1717687 L27.3245,54.494898 C28.49,55.2244898 29.822,54.7380952 29.8405,53.4098639 L29.8405,39.9217687 C29.8405,36.0119048 32.967,32.8503401 36.8335,32.8503401 L36.815,27.1071429 Z" transform="matrix(-1 0 0 1 73 0)" />
    </g>
  </g>
</svg>
    );
  }
}