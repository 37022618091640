
import React, { Component } from 'react';

export default class SpotifyPodcastIcon extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="61px" height="61px" viewBox="0 0 61 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>SpotifyPodcastIcon</title>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-685.000000, -730.000000)" fill="#FFFFFF">
      <g id="Listen-On-Section" transform="translate(685.000000, 666.000000)">
        <g id="Icons" transform="translate(0.000000, 64.000000)">
          <path d="M48.0456522,26.8934783 C38.376087,21.1521739 22.2097826,20.5478261 12.9934783,23.4184783 C11.4826087,23.8717391 9.97173913,22.9652174 9.51847826,21.6054348 C9.06521739,20.0945652 9.97173913,18.5836957 11.3315217,18.1304348 C22.0586957,14.9576087 39.7358696,15.5619565 50.9163043,22.2097826 C52.276087,22.9652174 52.7293478,24.7782609 51.973913,26.1380435 C51.2184783,27.1956522 49.4054348,27.648913 48.0456522,26.8934783 M47.7434783,35.3543478 C46.9880435,36.4119565 45.6282609,36.8652174 44.5706522,36.1097826 C36.4119565,31.123913 24.0228261,29.6130435 14.5043478,32.6347826 C13.2956522,32.9369565 11.9358696,32.3326087 11.6336957,31.123913 C11.3315217,29.9152174 11.9358696,28.5554348 13.1445652,28.2532609 C24.173913,24.9293478 37.7717391,26.5913043 47.1391304,32.3326087 C48.0456522,32.7858696 48.498913,34.2967391 47.7434783,35.3543478 M44.1173913,43.6641304 C43.5130435,44.5706522 42.4554348,44.8728261 41.548913,44.2684783 C34.4478261,39.8869565 25.5336957,38.9804348 14.9576087,41.3978261 C13.9,41.7 12.9934783,40.9445652 12.6913043,40.0380435 C12.3891304,38.9804348 13.1445652,38.073913 14.051087,37.7717391 C25.5336957,35.2032609 35.5054348,36.2608696 43.3619565,41.0956522 C44.4195652,41.548913 44.5706522,42.7576087 44.1173913,43.6641304 M30.2173913,0 C13.5978261,0 0,13.5978261 0,30.2173913 C0,46.8369565 13.5978261,60.4347826 30.2173913,60.4347826 C46.8369565,60.4347826 60.4347826,46.8369565 60.4347826,30.2173913 C60.4347826,13.5978261 46.9880435,0 30.2173913,0" id="SpotifyPodcastIcon" />
        </g>
      </g>
    </g>
  </g>
</svg>
    );
  }
}