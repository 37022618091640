
import React, { Component } from 'react';

export default class FooterIYELogo extends Component {

  componentDidMount(){
    if (this.props.getRef) this.props.getRef(this.ref);
  }

  render(){
    return (
      <svg {...this.props} ref={ref => this.ref = ref} width="50px" height="74px" viewBox="0 0 50 74" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <title>E</title>
  <g id="Site-Designs" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    <g id="Desktop-Wide" transform="translate(-775.000000, -4506.000000)" fill="#DADADA" fillRule="nonzero">
      <g id="Footer" transform="translate(-0.000000, 4465.000000)">
        <path d="M816.328679,115 C821.949094,115 825,111.969171 825,106.385742 L825,105.384321 C825,99.8008927 821.949094,96.7700633 816.328679,96.7700633 L801.469578,96.7700633 C798.332886,96.7700633 795.790095,94.2272719 795.790095,91.0905797 C795.790095,87.9538876 798.332886,85.4110962 801.469578,85.4110962 L814.477151,85.4110962 L814.477151,85.4110962 C820.097566,85.4110962 823.148472,82.762442 823.148472,77.8810288 L823.148472,77.7788431 C823.148472,72.9658942 820.097566,70.2855627 814.477151,70.2855627 L801.273892,70.2855627 C798.245274,70.2855627 795.790095,67.8303828 795.790095,64.8017649 C795.790095,61.773147 798.245274,59.3179671 801.273892,59.3179671 L816.328679,59.3179671 L816.328679,59.3179671 C821.949094,59.3179671 825,56.2871378 825,50.7037091 L825,49.6317801 C825,44.0483514 821.949094,41.017522 816.328679,41.017522 L795.847258,41 C783.584165,41 775,54.8717146 775,64.6062512 L775,92.106317 C775,101.719172 784.319951,114.982478 795.847258,114.982478 L816.328679,115 Z" id="E" style={{mixBlendMode: 'soft-light'}} />
      </g>
    </g>
  </g>
</svg>
    );
  }
}